import { graphql, Link } from "gatsby";
import React from "react";
import * as s from "../styles/blog-post.module.css";
import Layout from "./../components/layout";
import Post from "../components/post/post";
import Seo from "./../components/seo";
import Title from "./../components/title/title";

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes;

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title="All posts" />
        <div className="container">
          <Title>Blog</Title>
          {/* <Bio /> */}
          <p>
            No blog posts found. Add posts to your WordPress site and they'll
            appear here!
          </p>
        </div>
      </Layout>
    );
  }

  return (
    <Layout isHomePage>
      <Seo title="All posts" />
      <div className="container">
        <Title>Blog</Title>
        {/* <Bio /> */}
        <div className="row">
          {posts.map(post => {
            // const title = post.title
            return (
              <div className="col-md-6 col-lg-4 mb-3" key={post.uri}>
                <Post post={post} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="container">
        <div className={s.singleNavigation}>
          {previousPagePath && (
            <Link to={previousPagePath} rel="prev">
              Previous page
            </Link>
          )}
          {nextPagePath && (
            <Link to={nextPagePath} rel="next">
              Next page
            </Link>
          )}
        </div>
      </div>
      <section></section>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            gatsbyImage(width: 600,quality: 80)
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 80
                  placeholder: TRACED_SVG
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`;
